
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { BlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'
import Badge from '@/components/status/Badge.vue'
import { PagedResultSet } from '../../../../../tradingmate-modules/src/models/api/common'
import Paging from '@/components/actions/Paging.vue'
import BlogFilter from '../../../../../tradingmate-modules/src/filters/BlogFilter'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    Badge,
    Paging
  }
})

export default class AdminContentBlogs extends Vue {
  private blogs: PagedResultSet<BlogModel> | null = null

  private loading = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  private isMarkingSeen = ''

  private filter = new BlogFilter();

  mounted (): void {
    this.getBlogs()
  }

  getBlogs (): void {
    this.loading = true
    Services.API.Blogs.GetAllBlogs(this.filter)
      .then((res) => {
        this.blogs = res
      })
      .finally(() => {
        this.loading = false
      })
  }

  onBlogEdit (slug: string): void {
    this.$router.push({ name: 'Edit Blog', params: { slug: slug } })
  }

  onMarkedSeen (id: string): void {
    this.isMarkingSeen = id
    Services.API.Admin.Content.MarkSeenBlog(id)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        if (!this.blogs) return
        const blog = this.blogs.Items.find(x => x.BlogId === id)
        if (!blog) return
        blog.MarkedSeen = true
      })
      .finally(() => { this.isMarkingSeen = '' })
  }

  onPaged (page: number): void {
    this.filter.Page = page
    this.getBlogs()
  }
}

